import * as React from "react"
import { Link } from "gatsby"
import useCategories from "../../hooks/use-categories"
import useCompany from "../../hooks/use-company"
import paymentLogo from "../../images/tu-equipo-sas-pagos.png"

const Footer = () => {
  const categories = useCategories().getCategoryList()
  const {
    name,
    slogan,
    address,
    city,
    email,
    mobile,
    phone,
    facebook,
    twitter,
    linkedin,
  } = useCompany().get()
  return (
    <footer className="bg-dark py-5">
      <section className="container">
        <div className="row g-2 justify-content-center">
          {categories?.map(c => (
            <Link to={`/categoria/${c.slug}`} key={c.id} className="col-auto btn btn-default rounded-0 shadow-none px-4 py-2 me-1">
              {c.name}
            </Link>
          ))}
        </div>
        <section className="row text-center mt-3">
          <div className="col-12">
            <span>{name}</span>
          </div>
          <div className="col-12">
            <span>{slogan}</span>
          </div>
          <div className="col-12">
            <span>{address} {city}</span>
          </div>
        </section>

        <section className="row justify-content-sm-center pb-3">
          <div className="col-12 col-sm-auto text-sm-center text-md-right py-1">
            <span className="icon icon-phone"></span>
            <small> <span className="icon icon"></span> Teléfono</small><br />
            <span>{phone}</span>
          </div>
          <div className="col-12 col-sm-auto text-sm-center text-md-center py-1">
            <span className="icon icon-at-sign"></span>
            <small> Correo electrónico</small><br />
            <span>{email}</span>
          </div>
          <div className="col-12 col-sm-auto text-sm-center text-md-left py-1">
            <span className="icon icon-smartphone"></span>
            <small> Celular</small><br />
            <span>{mobile}</span>
          </div>
        </section>

        <section className="row justify-content-center">
          {
            facebook && (
              <a
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-social mr-3"
                title="Nuestra página de Facebook">
                <span className="icon icon-facebook"></span>
              </a>
            )
          }
          {
            twitter && (
              <a
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-social mr-3"
                title="Nuestra página de Twitter">
                <span className="icon icon-twitter"></span>
              </a>
            )
          }
          {
            linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-social"
                title="Nuestra página de Linked In">
                <span className="icon icon-linkedin"></span>
              </a>
            )
          }
        </section>


        <section className="container pt-3">
          <div className="text-center">
            <small className="d-block">Realiza compras seguras a través de</small>
            <img className="payments mt-2" alt="tu-equipo-sas-pagos" src={paymentLogo} />
          </div>
        </section>
      </section>
    </footer>
  )
}

export default Footer