import { useStaticQuery, graphql } from "gatsby"

const useCategories = () => {
  const { tuequipoJson: { data: { categories } } } = useStaticQuery(graphql`{
    tuequipoJson {
      data {
        categories {
          id
          name
          slug
          description
          image {
            url
          }
          seo {
            description
            keywords
            title
          }
          category
        }
      }
    }
  }`)
  return ({
    getAll: () => {
      return categories || []
    },
    getCategories: () => {
      return categories?.filter(c => c.category === '') || []
    },
    getCategoryList: () => {
      return categories?.filter(c => c.category === '').map(({ id, slug, name }) => ({ id, slug, name })) || []
    },
    getSubCategoriesList: () => {
      return categories.filter(c => c.category !== '').map(({ id, slug, name, category }) => ({ id, slug, name, category })) || []
    },
    getSubcategories: (id) => {
      return categories?.filter(c => c.category === id).map(({ id, slug, name }) => ({ id, slug, name })) || []
    },
    getCategory: (id) => {
      return categories?.find(c => c.id === id) || null
    },
    getParentCategory: (id) => {
      return categories?.map(({ id, slug, name }) => ({ id, slug, name })).find(c => c.id === id) || null
    },
    getCategoryIncludes: (ids) => {
      return categories?.filter(c => ids.includes(c.id) && !c.category ).map(({ slug, name }) => ({ slug, name })) || null
    },
  })
}

export default useCategories
