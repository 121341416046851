import { graphql, useStaticQuery } from "gatsby"

const useCompany = () => {
  const { tuequipoJson: { data: { company } }, site: { siteMetadata } } = useStaticQuery(
    graphql`{
      site {
        siteMetadata {
          siteUrl
          title
          description
          keywords
          sendMailUrl
          themeColor
          websiteId
        }
      }
      tuequipoJson {
        data {
          company {
            name
            identification
            slogan
            address 
            city
            phone
            email
            mobile
            facebook
            linkedin
            twitter
            seo {
              description
              keywords
              title
            }
          }
        }
      }
    }`
  )
  return ({
    get: () => {
      return company
    },
    getSite: () => {
      return siteMetadata
    },
  })

}

export default useCompany