import * as React from "react"
import Helmet from "react-helmet"

import useCompany from "../../hooks/use-company"

import Navbar from "../ui/navbar"
import Search from "../ui/search"
import Footer from "../ui/footer"

const location = typeof window !== `undefined` ? window.location : null

const MainLayout = ({ children, title, description, keywords }) => {
  const { seo } = useCompany().get()
  const site = useCompany().getSite()
  const _title = title ||  seo.title || site.title || ''
  const _description = description ||  seo.description || site.description || ''
  const _keywords = keywords ||  seo.keywords || site.keywords || ''
  const _url = location?.href
  return (
    <>
      <Helmet>
        <html lang="es"/>
        <title>{_title}</title>
        <meta name="theme-color" content={site.themeColor} />
        <meta name="apple-mobile-web-app-status-bar-style" content={site.themeColor} />
        <meta name="msapplication-navbutton-color" content={site.themeColor} />
        <meta name="description" content={_description} />
        <meta name="keywords" content={_keywords} />

        <meta name="og:type" content="website" />
        <meta name="og:title" content={_title} />
        <meta name="og:og:description" content={_description} />
        <meta name="og:url" content={_url} />
        <meta name="og:site_name" content={title} />

        <meta name="author" content={site.title} />
        <meta name="copyright" content={site.title} />
        <meta http-equiv="Cache-Control" content="public;max-age=31536000;must-revalidate" />
        <meta http-equiv="Pragma" content="public;max-age=31536000;must-revalidate" />
        <script type="text/javascript" src="https://s.cliengo.com/weboptimizer/5def9e68e4b097f9419e479d/5def9e96e4b01045acb3f560.js" defer />
      </Helmet>
      <Navbar />
      <Search />
      <main>
        { children }
      </main>
      <Footer />
    </>
  )  
}
export default MainLayout