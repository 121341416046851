import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
  const width = 200
  const height = 40
  return (
    <StaticImage src="../../images/logo-tu-equipo-sas.png" width={width} height={height} layout="fixed" alt="Logotipo Tu Equipo SAS"/>
  )
}

export default Logo;