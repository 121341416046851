import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
const bootstrap = typeof window !== `undefined` ? require('bootstrap') : null

const Navbar = () => {
  const menus = [
    { id: 1, url: 'productos', title: 'Productos'},
    { id: 2, url: 'promociones', title: 'Promociones'},
    { id: 3, url: 'quienes-somos', title: 'Quiénes somos'},
    { id: 4, url: 'contactenos', title: 'Contáctenos'},
  ]
  const [navbarButton, setNavbarButton] = useState(null)

  const handleClick = () => {
    navbarButton.toggle()
  }

  useEffect(() => {
    if (bootstrap) {
			const collapseElements = [].slice.call(document.querySelectorAll('.collapse'))
			const { Collapse } = bootstrap
			const [menuButton] = collapseElements.map(function (element) {
					return new Collapse(element, { toggle: false })
			})
			setNavbarButton(menuButton)
		}
  }, [])


  return (
    <nav className="navbar sticky-top navbar-expand-md navbar-light bg-default shadow">
      <div className="container">
          <Link to="/">
            <Logo />
          </Link>
          <button className="navbar-toggler shadow-sm" type="button" aria-expanded="false" aria-label="Menú principal" onClick={handleClick}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="headerMenuOptions">
            <ul className="navbar-nav ms-auto mt-2 mt-md-0">
              { menus.map(m => (
                <li className="nav-item" key={m.id}>
                  <Link to={`/${m.url}`} className="nav-link px-4 px-md-3 px-xl-4 py-2 font-quicksand font-bold">
                    {m.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
      </div>
    </nav>
  )
}

export default Navbar