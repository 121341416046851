import * as React from "react"
import { useState } from "react"
import { navigate } from "gatsby"
import useCompany from "../../hooks/use-company"

const Search = () => {
  const { facebook, mobile } = useCompany().get()
  const [product, setProduct] = useState('')
  
  const handleInputChange = (event) => {
    setProduct(event.target.value)
  }

  const handleLinkProduct = () => {
    if(product) {
      navigate(`/buscar-productos?q=${product}`)
    }
  }

  return (
    <section className="bg-dark bg-gradient shadow">
      <div className="container py-2">
        <div className="row align-items-center pt-4 pt-md-0">
          <div className="col-12 col-md-6">
            <div className="input-group">
              <input
                type="text"
                className="form-control shadow-none"
                placeholder="Buscar productos"
                aria-label="Buscar productos"
                aria-describedby="button-search"
                onChange={handleInputChange}
                value={product}
              />
              <button
                className="btn btn-outline-warning shadow-none"
                type="button"
                aria-label="Buscar productos"
                onClick={handleLinkProduct}>
                <span className="icon icon-search"></span>
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 align-middle pt-2 text-end">
            <a 
              href={`tel:+57${mobile}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow text-decoration-none py-2 me-3"
              title="Nuestra página de Facebook">
              <span className="icon icon-smartphone"></span> {mobile}
            </a>
            <a 
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow text-decoration-none py-2 px-3"
              title="Nuestra página de Facebook">
              <span className="icon icon-facebook"></span>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Search